<template>
  <div>
    <el-tabs value="first" @tab-click="handleClick">
      <el-tab-pane label="桌面端权限" name="first"><pc-permissions ref="pc"></pc-permissions></el-tab-pane>
      <el-tab-pane label="微信端权限" name="second"><wx-permissions ref="app"></wx-permissions></el-tab-pane>
      <el-tab-pane label="物业端权限" name="third"><wu-ye-permissions ref="wuyeapp"></wu-ye-permissions></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import {getWidth} from "@/util";
  import PcPermissions from "@/views/basic/PcPermissions";
  import WxPermissions from "@/views/basic/WxPermissions";
  import WuYePermissions from "@/views/basic/WuYePermissions";
  export default {
    name: "Permissions",
    components: {WxPermissions, PcPermissions,WuYePermissions},
    data() {
      return {
        data:[],
      };
    },
    mounted() {
      // this.getTreeList();
    },
    methods:{
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/authority/tree");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleClick(tab) {
        if (tab.name==="first") {
          this.$refs.pc.getMenu();
        }else if (tab.name==="second") {
          this.$refs.app.getMenu();
        }else if (tab.name==="third") {
          this.$refs.wuyeapp.getMenu();
        }
      },
    },
  };
</script>

<style scoped>

</style>
