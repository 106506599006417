<template>
  <div v-loading="contentLoading" class="role-edit">
    <el-button style="margin-bottom: 10px" type="primary" @click="$refs.PcPermissionsEdit.open({type:'customer_company_app'})">新增</el-button>
    <div class="permissionContent">
      <div
        v-for="(item,index) in menus"
        :key="index">
        <div class="permissionTitle" style="background-color: #E6E6E6">
          <h1 style="display: inline-block">
            {{item[$l("role.en","label")]}}
            <el-tag style="margin: 0 5px">一级菜单</el-tag>
          </h1>
          <span style="float: right">
            <el-button v-if="item.other.authorityType==='menu'" s type="text" @click="$refs.PcPermissionsEdit.open({parentId:item.id,type:'customer_company_app'})">新增</el-button>
            <el-button type="text" @click="$refs.PcPermissionsEdit.open({id:item.id,type:'customer_company_app'})">编辑</el-button>
            <el-button type="text" style="color: red" @click="deleteNode(item)">删除</el-button>
          </span>
        </div>

        <span v-for="(itemChild,indexChild) in item.children" :key="indexChild">

          <span v-if="itemChild.other.authorityType!=='menu'" :style="{width:itemChild.children?'100%':'100px',paddingLeft: '15px',marginBottom: '8px',backgroundColor:''}">
            {{$i18n.locale==="zh-CN"?itemChild.label:itemChild.label}}<el-tag style="margin: 0 5px">{{itemChild.other.authorityTypeDesc}}</el-tag>
            <el-button v-if="itemChild.other.authorityType==='menu'" type="text" @click="$refs.PcPermissionsEdit.open({parentId:itemChild.id,type:'customer_company_app'})">新增</el-button>
            <el-button type="text" @click="$refs.PcPermissionsEdit.open({id:itemChild.id,type:'customer_company_app'})">编辑</el-button>
            <el-button type="text" style="color: red" @click="deleteNode(itemChild)">删除</el-button>
          </span>
          <h2
            v-if="itemChild.other.authorityType==='menu'"
            :style="{width:'100%',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'#F5F5F5'}">
            {{$i18n.locale==="zh-CN"?itemChild.label:itemChild.label}}<el-tag style="margin: 0 5px">二级菜单</el-tag>

            <span v-if="itemChild.other.authorityType==='menu'" style="float: right;">
              <el-button type="text" @click="$refs.PcPermissionsEdit.open({parentId:itemChild.id,type:'customer_company_app'})">新增</el-button>
              <el-button type="text" @click="$refs.PcPermissionsEdit.open({id:itemChild.id,type:'customer_company_app'})">编辑</el-button>
              <el-button type="text" style="color: red" @click="deleteNode(itemChild)">删除</el-button>
            </span>
          </h2>

          <!--            <span v-if="itemChild.children">展开</span>-->
          <div v-if="itemChild.children">
            <span
              v-for="(itemChildren,indexChildren) in itemChild.children"
              :key="indexChildren"
              :label="itemChildren.label"
              :style="{width:$l('role.permissionWidth','100px')}"
              style="margin-left: 15px;margin-bottom: 8px">
              {{$i18n.locale==="zh-CN"?itemChildren.label:itemChildren.label}}<el-tag style="margin: 0 5px">{{itemChildren.other.authorityTypeDesc}}</el-tag>
              <el-button type="text" @click="$refs.PcPermissionsEdit.open({id:itemChildren.id})">编辑</el-button>
              <el-button type="text" style="color: red" @click="deleteNode(itemChildren)">删除</el-button>
            </span>
          </div>

        </span>
      </div>
    </div>
    <pc-permissions-edit ref="PcPermissionsEdit" @save-success="getMenu"></pc-permissions-edit>
  </div>
</template>

<script>
  import PcPermissionsEdit from "@/views/basic/PcPermissionsEdit";
  export default {
    components:{
      PcPermissionsEdit,
    },
    data() {
      return{
        menus:[],
      };
    },
    mounted() {this.getMenu();
    },
    methods:{
      getMenu() {
        this.$http.get("/basic/authority/tree",{
          systemType:"customer_company_app",
        }).then(res=>{
          console.log(res,119);

          this.menus =res;
          this.getData();
        });
      },
      deleteNode(item) {
        this.$http.delete(`/basic/authority/${item.id}`).then(()=>{
          this.$message.success("删除成功");
          this.getMenu();
        });
        console.log(item);
      },


    },
  };
</script>

<style lang="scss">
@import "../../style/variables";

.role-edit {
  width: 800px;
  .permissionContent {
    border: 1px solid $border-color;
    border-radius: 2px;
  }

  .permissionTitle {
    font-size: 13px;
    padding: 9px 12px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
  }

  .el-checkbox__label {
    font-size: 16px;
  }

}
</style>
