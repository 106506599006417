<template>
  <div v-loading="contentLoading" class="role-edit">
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      @click="$refs.PcPermissionsEdit.open({type:'maintenance_company_backend'})">
      新增
    </el-button>
    <div class="permissionContent">
      <vuedraggable :list.sync="menus" :move="move" @end="submit">
        <div
          v-for="(item,index) in menus"
          :key="index">
          <div class="permissionTitle" style="background-color: #E6E6E6">
            <h1 style="display: inline-block">
              {{item[$l("role.en", "label")]}}
              <el-tag style="margin: 0 5px">一级菜单</el-tag>
            </h1>
            <span style="float: right">
              <el-button
                type="text"
                @click="$refs.PcPermissionsEdit.open({parentId:item.id,type:'maintenance_company_backend'})">新增</el-button
                >
              <el-button
                type="text"
                @click="$refs.PcPermissionsEdit.open({id:item.id,type:'maintenance_company_backend'})">编辑</el-button>
              <el-button style="color: red" type="text" @click="deleteNode(item)">删除</el-button>
            </span>
          </div>
          <vuedraggable :list.sync="item.children" :move="move" @end="submit">
            <span v-for="(itemChild,indexChild) in item.children" :key="indexChild">
              <span
                v-if="itemChild.other.authorityType==='button'"
                :style="{width:itemChild.children?'100%':'100px',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'',display: 'inline-block'}">
                <el-tag closable style="margin: 0 5px" @click="$refs.PcPermissionsEdit.open({id:itemChild.id,type:'maintenance_company_backend'})">{{$i18n.locale === "zh-CN" ? itemChild.label : itemChild.label}}
                </el-tag>
                <!--            <el-button-->
                <!--              type="text"-->
                <!--              @click="$refs.PcPermissionsEdit.open({id:itemChild.id,type:'maintenance_company_backend'})">编辑</el-button>-->
                <!--            <el-button style="color: red" type="text" @click="deleteNode(itemChild)">删除</el-button>-->
              </span>
              <h2
                v-if="itemChild.other.authorityType!=='button'"
                :style="{width:'100%',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'#F5F5F5'}">
                {{$i18n.locale === "zh-CN" ? itemChild.label : itemChild.label}}<el-tag>二级{{itemChild.other.authorityTypeDesc}}</el-tag>
                <span v-if="itemChild.other.authorityType!=='button'" style="float: right;">
                  <el-button
                    type="text"
                    @click="$refs.PcPermissionsEdit.open({parentId:itemChild.id,type:'maintenance_company_backend'})">新增</el-button>
                  <el-button
                    type="text"
                    @click="$refs.PcPermissionsEdit.open({id:itemChild.id,type:'maintenance_company_backend'})">编辑</el-button>
                  <el-button style="color: red" type="text" @click="deleteNode(itemChild)">删除</el-button>
                </span>
              </h2>
              <span v-if="itemChild.children">
                <span
                  v-for="(itemChildren,indexChildren) in itemChild.children"
                  :key="indexChildren"
                  :label="itemChildren.label"
                  :style="{width:$l('role.permissionWidth','100px')}"
                >
                  <span v-if="itemChildren.other.authorityType==='button'" style="margin-left: 15px;margin-bottom: 8px;'100px';display: inline-block">
                    <el-tag closable style="margin: 0 5px" @click="$refs.PcPermissionsEdit.open({id:itemChildren.id,type:'maintenance_company_backend'})" @close="deleteNode(itemChildren)">{{$i18n.locale === "zh-CN" ? itemChildren.label : itemChildren.label}}
                    </el-tag>
               
                  </span>

                  <h3
                    v-if="itemChildren.other.authorityType!=='button'"
                    :style="{width:'100%',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'rgba(245,245,245,0.99)'}">
                    {{$i18n.locale === "zh-CN" ? itemChildren.label : itemChildren.label}}<el-tag
                      style="margin: 0 5px">三级{{itemChildren.other.authorityTypeDesc}}</el-tag>
                    <span v-if="itemChild.other.authorityType!=='button'" style="float: right;">
                      <el-button
                        type="text"
                        @click="$refs.PcPermissionsEdit.open({parentId:itemChildren.id,type:'maintenance_company_backend'})">新增</el-button>
                      <el-button
                        type="text"
                        @click="$refs.PcPermissionsEdit.open({id:itemChildren.id,type:'maintenance_company_backend'})">编辑</el-button>
                      <el-button style="color: red" type="text" @click="deleteNode(itemChildren)">删除</el-button>
                    </span>
                  </h3>
                  <span v-if="itemChildren.children">
                    <span
                      v-for="(lastNode,i) in itemChildren.children"
                      :key="i"
                      :label="itemChildren.label"
                      style="margin-left: 15px;margin-bottom: 8px;'100px';display: inline-block">
                      <el-tag closable style="margin: 0 8px" @click="$refs.PcPermissionsEdit.open({id:lastNode.id,type:'maintenance_company_backend'})" @close="deleteNode(lastNode)">{{$i18n.locale === "zh-CN" ? lastNode.label : lastNode.label}}
                      </el-tag>
                    </span>

                  </span>

                </span>
              </span>

            </span>
          </vuedraggable>
        </div>
      </vuedraggable>
    </div>
    <pc-permissions-edit ref="PcPermissionsEdit" @save-success="getMenu"></pc-permissions-edit>
  </div>
</template>

<script>
  import PcPermissionsEdit from "@/views/basic/PcPermissionsEdit";
  import vuedraggable from "vuedraggable";
  export default {
    name: "PcPermissions",
    components: {
      PcPermissionsEdit,
      vuedraggable,
    },
    data() {
      return {
        menus: [],
        list:[],
      };
    },
    mounted() {
      this.getMenu();
    },
    methods: {
      move(e) {
        this.list = e.relatedContext.list;
        return true;
      },
      submit() {
        this.$http.post("/basic/authority/sequence",this.list).then(()=>{
          this.getMenu();
        });

      },
      getMenu() {
        this.$http.get("/basic/authority/tree", {
          systemType: "maintenance_company_backend",
        }).then(res => {
          console.log(res, 119);

          this.menus = res;
        });
      },
      deleteNode(item) {
        this.$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http.delete(`/basic/authority/${item.id}`).then(() => {
            this.$message.success("删除成功");
            this.getMenu();
          });
        }).catch(() => {

        });

        console.log(item);
      },


    },
  };
</script>

<style lang="scss">
@import "../../style/variables";

.role-edit {
  //width: 1200px;

  .permissionContent {
    border: 1px solid $border-color;
    border-radius: 2px;
  }

  .permissionTitle {
    font-size: 13px;
    padding: 9px 12px;
    background-color: #E6E6E6;
    margin-bottom: 10px;
  }

  .el-checkbox__label {
    font-size: 16px;
  }

}
</style>