<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑菜单':'新增菜单'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="上级ID" prop="parentId">
        <el-input v-model="model.parentId" disabled :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="权限名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>

      <el-form-item label="权限类型" prop="authorityType" :rules="$rule.notNull">
        <vm-dict-select v-model="model.authorityType" type="authorityType"></vm-dict-select>
      </el-form-item>
      <el-form-item label="权限标识" prop="uniqueKey">
        <el-input v-model="model.uniqueKey" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="系统分类" prop="systemType" :rules="$rule.notNull">
        <vm-dict-select v-model="model.systemType" disabled type="systemType"></vm-dict-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          parentId:null,
          name: "",
          authorityType: "",
          systemType: "",
          uniqueKey: "",

        },

      };
      
    },
    methods: {
      open(pramas) {
        if (pramas) {
          if (pramas.id) {
            this.model.id = pramas.id;
            this.getDetail();
          }else if (pramas.parentId) {
            this.model.parentId = pramas.parentId;
          }
          if (pramas.type) {
            this.model.systemType=pramas.type;
          }
        }

        this.dialogVisible=true;


      },
      getDetail() {
        this.$http.get(`/basic/authority/${this.model.id}`).then(res=>{
          this.model=res;
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("/basic/authority", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
